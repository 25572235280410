:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(210, 90%, 20%);
    --clr-primary-2: hsl(210, 84%, 25%);
    --clr-primary-3: hsl(210, 81%, 29%);
    --clr-primary-4: hsl(210, 77%, 34%);
    --clr-primary-5: hsl(210, 62%, 45%);
    --clr-primary-6: hsl(210, 57%, 50%);
    --clr-primary-7: hsl(210, 65%, 59%);
    --clr-primary-8: hsl(210, 80%, 74%);
    --clr-primary-9: hsl(210, 94%, 87%);
    --clr-primary-10: hsl(210, 100%, 94%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
    --fixed-height:20px
  
    --clr-text-light:hsl(0, 0%, 100%);
    --clr-bg-hoover-light:hsl(200, 40%, 75%);
    --clr-link-hover:hsl(200, 40%, 90%);
}

::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

  /* GENERAL */

*{
  letter-spacing: var(--spacing);
  font-weight: 500;
}

 
.span-myclinic{
  /* color: hsl(142, 90%, 45%); */
  color:var(--clr-green-dark);
  font-weight: bold;
}

.ml-2 {
  margin-left: 2rem;
}

.image-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container-right{
  max-width: 250px;
  float:right;
  margin: 1rem;
}


.image-container {
  display: flex;
  object-fit: cover;
  margin: 0.5rem;
  max-width: 250px;
}

.image-container-right{
  display: flex;
  float: right;
  margin: 0.5rem;
  max-width: 250px;
}

.clearfix {
  overflow: auto;
}

@media only screen and (max-width: 520px){
  .image-container{
    max-width:150px;
  }
}
/* .backgroundImage {
  max-height:"40rem";
  background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 250, 250, 0.7)),
  url('../images/doctor.jpg');
  background-size:contain no-repeat;
  background-position: center;
  min-height: 80vw;
  border: 0;
} */


.card-image{
  max-width: 300px;
  max-height: 350px;
  object-fit: contain;
}

.card-small {
  font-size: small;
}


main {
  min-height: 65vh;
  text-indent:2rem;
}

.scroll-overflow{
  overflow: scroll;
}

.box-shadow-left{
  box-shadow: -5px 3px 3px rgb(181, 198, 202);
}

.box-shadow-right {
  box-shadow: 5px 3px 3px rgb(60, 109, 123);
}

@media only screen and (max-width: 800px) {
  main {
    font-size:small;
  }
    h1{
      font-size: 180%;
      text-align: center;
    }
    h2,
    h3,
    h4,
    h5{
      font-size: 150%;
      text-align: center;
    }
}

img {
  max-width: 100%;
} 

/* HOME PAGE CUSTOMIZATION */

.carousel-caption {
  color: #fff;
  
}

#home-container{
  font-family: Roboto,Arial,Helvetica,sans-serif;
  margin:3rem 0;
  padding: 0;
  line-height: 80%;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  text-indent: 1rem;
  line-height: 1.5rem;
}

.carusel-image-cover{
  display: flex;
  width: 400px;
  height: 400px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
}
.carusel-image-contain{
  display: flex;
  width: 400px;
  height: 400px;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

/* NAVIGATION */

.navigation {
  text-size-adjust: 120%;
  letter-spacing: 0.5em;
  /* background: linear-gradient(to right ,#ffffff,rgb(52, 52, 255), rgb(146, 146, 255)); */
  background: rgb(224, 244, 244);
}

.navigation .navbar-nav .nav-link:hover{
  background-color: var(--clr-primary-9);
}

.navigation .navbar-nav .nav-link {
  white-space: nowrap;
}

.navigation .dropdown-item:hover{
  background-color: var(--clr-bg-hoover-light);
} 

.navigation .dropdown-item {
  white-space: normal;
  word-break: keep-all;
  
}

/* case box for dropdown menu items */
.navigation .dropdown-menu{
  margin-top: 0; 
  max-width: 15rem;
  min-width: 12rem;
  box-shadow: 5px 3px 3px rgb(60, 109, 123);
}

/* HOVER DROPDOWN --> this will make dropdown to drop on hover */
.navigation .dropdown:hover .dropdown-menu {
  display: block;
}
/* MOBILE */
@media (max-width: 767px) {
  .navbar-collapse {
    -webkit-transition: height 0.35s ease;
    -moz-transition: height 0.35s ease;
    transition: height 0.35s ease;
    overflow-y: auto !important;
    max-height: calc(100vh - 56px) !important;
  }
}



/* HEADER */



.motto-header {
  /* font-family: cursive; */
  margin:0 0 2rem 0rem ;
  padding: 1rem 0 ;
  text-align: center;
  background-color: var(--clr-primary-9);
  font-weight: 100;
}

/* FOOTER */

.footer {

  background: rgb(83, 84, 84);
  padding: 2rem;
  color: var(--clr-text-light);

}

.footer .nav-link {
  color: rgb(254, 254, 254);
  text-align: center;
}

.footer .nav-link:hover{
  color: var(--clr-link-hover);
  font-size: 105%;
  transition: var(--transition);
  text-overflow: unset;
}

/* .footer .col {
  min-height: var(--fixed-height);
} */

/* MAP */

 .map-container {
   height: 500px;
 }

 .map-sidebar {
   background-color: rgba(35, 55, 75, 0.9);
   color: #fff;
   padding: 6px 12px;
   font-family: monospace;
   z-index: 1;
   position: absolute;
   top: 0;
   left: 0;
   margin: 12px;
   border-radius: 4px;
 }



